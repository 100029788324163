<template>
  <v-container class="background fill-height d-block" fluid>
    <!--  Navigation  -->
    <u-project-top-nav :title="activeProjectName" extended>
      <template #extension>
        <u-stepper-progression-variant
          :step="stepper.step"
          :sub-step="stepper.subSteps"
          :subtitle="stepper.subtitle"
          :title="stepper.title"
          @click:prev="$router.push(stepper.prevLink)"
          @click:next="$router.push(stepper.nextLink)"
        />
      </template>
    </u-project-top-nav>

    <!--  Breadcrumbs  -->
    <v-container class="pb-0" fluid>
      <v-row justify="space-between">
        <v-col>
          <!--  Breadcrumbs  -->
          <u-breadcrumbs :items="breadcrumbItems" />
          <h1 class="secondary--text">Video Upload</h1>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Action Buttons -->
        <v-col v-if="stagedFiles.length" align-self="end" cols="auto">
          <u-button
            class="rounded text-capitalize font-weight-bold"
            color="gray-7"
            @click="clearUploadedVideos"
          >
            Clear Completed
          </u-button>
        </v-col>
      </v-row>
    </v-container>

    <!-- Camera Videos Upload List -->
    <v-sheet
      :height="'65vh'"
      class="pa-4 ma-2 card-border"
      outlined
      rounded="lg"
    >
      <v-container
        class="fill-height overflow-y-auto align-content-start"
        fluid
      >
        <video-upload-list
          v-for="(camera, key, index) in videosByCamera"
          :key="key"
          :cameraIndex="index + 1"
          :cameraName="camera.camera_name"
          :cameraVideos="camera.videos"
        />
      </v-container>
    </v-sheet>

    <!-- Action Button  -->
    <v-row class="px-4 my-5" justify="end">
      <u-button
        class="text-capitalize font-weight-bold secondary--text"
        color="primary"
        large
        @click="$router.push({ name: 'Video Library' })"
      >
        Open Video Library
      </u-button>
    </v-row>
  </v-container>
</template>

<script>
import { UBreadcrumbs, UButton } from "@/components/base";
import {
  UProjectTopNav,
  UStepperProgressionVariant,
} from "@/components/common";
import { VideoUploadList } from "@/components";
import { mapGetters, mapState } from "vuex";
import { PROJECT_SETUP_STEPPER } from "@/utils/navStepper.data";
import { PROJECT_SETUP_BREADCRUMBS } from "@/utils/breadcrumbs.data";

export default {
  name: "VideoUpload",
  components: {
    UStepperProgressionVariant,
    UProjectTopNav,
    UBreadcrumbs,
    UButton,
    VideoUploadList,
  },
  data() {
    return {
      breadcrumbItems: PROJECT_SETUP_BREADCRUMBS,
      selectedVideo: undefined,
      stepper: PROJECT_SETUP_STEPPER.videoUpload,
    };
  },
  computed: {
    ...mapGetters({
      videosByCamera: "VideoUpload/getStagedFilesByCamera",
      activeProjectName: "Project/activeProjectName",
      stagedFiles: "VideoUpload/stagedFiles",
    }),
    ...mapState("VideoUpload", ["videoFiles"]),
  },
  methods: {
    clearUploadedVideos() {
      this.$store.dispatch("VideoUpload/clearUploadedVideos");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}
</style>
